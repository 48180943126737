<template>
  <div class="text-center text-white min-h-200">
<h1 class="text-3xl p-5">Loading Spinners</h1>
<h2 class="p-5">Nr.1</h2>
  <LoadingSpinnerNr1/>
    <h2 class="p-5">Nr.2</h2>
  <LoadingSpinnerNr2/>
    <h2 class="p-5">Nr.3</h2>
  <LoadingSpinnerNr3/>
    <h2 class="p-5">Nr.4</h2>
  <LoadingSpinnerNr4/>
    <h2 class="p-5">Nr.5</h2>
    <LoadingSpinnerNr5/>

    <h2 class="p-5 pt-80 mt-44">Nr.6</h2>
    <LoadingSpinnerNr6/>

    <h2 class="p-5">Nr.7</h2>
    <LoadingSpinnerNr7/>

    <h2 class="p-5">Nr.8</h2>

    <LoadingSpinnerNr8/>

  </div>


</template>




<script>

import LoadingSpinnerNr1 from '@/components/partials/LoadingSpinners/LoadingSpinnerNr1'
import LoadingSpinnerNr2 from '@/components/partials/LoadingSpinners/LoadingSpinnerNr2'
import LoadingSpinnerNr3 from '@/components/partials/LoadingSpinners/LoadingSpinnerNr3'
import LoadingSpinnerNr4 from '@/components/partials/LoadingSpinners/LoadingSpinnerNr4'
import LoadingSpinnerNr5 from '@/components/partials/LoadingSpinners/LoadingSpinnerNr5'
import LoadingSpinnerNr6 from '@/components/partials/LoadingSpinners/LoadingSpinnerNr6'
import LoadingSpinnerNr7 from '@/components/partials/LoadingSpinners/LoadingSpinnerNr7'
import LoadingSpinnerNr8 from '@/components/partials/LoadingSpinners/LoadingSpinnerNr8'

export default {

  components: {

    LoadingSpinnerNr1,LoadingSpinnerNr2,LoadingSpinnerNr3,LoadingSpinnerNr4,LoadingSpinnerNr5,LoadingSpinnerNr6,LoadingSpinnerNr7,LoadingSpinnerNr8

  }
}
</script>

<style scoped>

div {

  background-color: #03374c;
}
</style>