<template>
  <div class="w-1/12 mx-auto"></div>
</template>

<script>
export default {
  name: "LoadingSpinnerNr6"
}
</script>

<style scoped lang="scss">

$p: 6.25em;
$r: 1.5em;
$b: 20*$r;
$d: 2*($b - $r);
$c: /*#001f3f, #f012be, */#490a3d, /*#bd1550,*/ #e97f02/*, #f8ca00, #8a9b0f*/;
$n: length($c);
$q: 20%;
$t: 1s;

html {
  background: #333;
  filter: drop-shadow(2px 2px 5px rgba(#000, .5))
}

body {
  display: grid;
  place-content: center;
  margin: 0;
  height: 100vh;
  animation: fsx 4*$t steps(1) infinite
}

@keyframes fsx { 50% { transform: scale(-1) } }

div {
  overflow: hidden;
  position: relative;
  padding: $p;
  border-radius: 1em;
  animation:
      fbg 2*$n*$t steps(1) #{-$t} infinite,
      fsc 2*$t steps(1) infinite;

  &::after {
    position: absolute;
    top: 50%; left: 50%;
    margin: -$r (-.5*$d - $r);
    padding: $b;
    transform-origin: 50% 0;
    animation:
        fbg 2*$n*$t steps(1) -3*$t infinite,
        mov $t infinite alternate,
        exp $t ease-in infinite alternate;
    content: ''
  }
}

@keyframes fsc { 50% { transform: scaley(-1) } }

@keyframes fbg {
  @for $i from 0 to $n {
    #{$i/$n*100%} { background: nth($c, $i + 1) }
  }
}

@keyframes mov {
  0%, #{$q} { transform: none }
  100% { transform: translatey($r) rotate(.5turn) }
}

@keyframes exp {
  0%, #{$q} { clip-path: inset(0 .5*$d $d round 50%) }
  100% { clip-path: inset(0 round 50%/ 0) }
}
</style>