<template>
  <svg class="mx-auto" width="140" height="140" viewBox="0 0 280 280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <line x1="59.9833" y1="140.333" x2="219.978" y2="139" stroke="#000" stroke-width="4"/>
      <circle cx="60" cy="140" r="5" fill="#000"/>
      <circle cx="220" cy="139" r="5" fill="#000"/>
    </g>
    <path class="circle" d="M109.957 122.655L140 105.309L170.043 122.655V157.345L140 174.691L109.957 157.345V122.655Z" stroke="#000" stroke-width="4"/>
    <circle class="circle" cx="140" cy="140" r="13" stroke="#f5d77b" stroke-width="4"/>
    <circle class="circle" cx="110" cy="192" r="13" stroke="#f7a78f" stroke-width="4"/>
    <circle class="circle circle_s" cx="85" cy="232" r="8" stroke="#82c7c5" stroke-width="4"/>
    <circle class="circle" cx="170" cy="88" r="13" stroke="#82c7c5" stroke-width="4"/>
    <circle class="circle circle_s" cx="110" cy="192" r="5" fill="#f7a78f"/>
    <circle class="circle circle_s" cx="185" cy="61" r="5" fill="#f5d77b"/>
  </svg>

</template>

<script>
export default {
  name: "LoadingSpinnerNr1"
}
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap");

@keyframes rotation {
  from {
    -webkit-transform: rotate(359deg);
  }

  to {
    -webkit-transform: rotate(0deg);
  }
}

html {
  height: 100%;
}

body {
  margin: 0;
  background-color: #03374c;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Raleway', sans-serif;
}

svg * {
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.circle {
  -webkit-animation: rotation 8s infinite linear;
}

.circle_s {
  -webkit-animation: rotation 4s infinite linear;
}
</style>